<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <a href="#" class="flex-column-auto">
            <img src="media/logos/logo-letter-1.png" class="h-25" />
          </a>
          <div
            class="flex-column-fluid d-flex flex-column justify-content-center"
          >
            <h3 class="font-size-h1 mt-10 mb-5 text-white">
              Hai {{ fullName }}
            </h3>
            <p class="font-weight-lighter text-white opacity-80">
              Silahkan atur password akun anda dulu ya, agar bisa masuk ke
              aplikasi
            </p>
          </div>
          <div
            class="d-none flex-column-auto d-lg-flex justify-content-between mt-15"
          >
            <div class="opacity-70 font-weight-bold text-white">
              2024 © Build with ♥️ by PT Damaco Dev Teknologi
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          <div class="login-form login-signin">
            <b-form class="form w-300px" @submit.stop.prevent="handleSubmit">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Setup Password
                </h3>
              </div>
              <InputText
                label="Password Baru"
                placeholder="Masukin passwordnya disini"
                type="password"
                label-class="font-size-h6 font-weight-bolder text-dark"
                content-class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                v-model="form.newPassword"
                :v="$v.form.newPassword"
                :useHorizontal="false"
              />
              <InputText
                label="Konfirmasi Password Baru"
                placeholder="Masukin passwordnya disini"
                type="password"
                label-class="font-size-h6 font-weight-bolder text-dark"
                content-class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                v-model="form.confirmNewPassword"
                :v="$v.form.confirmNewPassword"
                :useHorizontal="false"
              />

              <div class="pb-lg-0 pb-5">
                <b-button
                  class="btn font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "auth",
  data: () => ({
    fullName: null,
    form: {
      newPassword: null,
      confirmNewPassword: null,
    },
  }),
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-4.jpg";
    },
  },
  validations: {
    form: {
      newPassword: {
        required,
        sameAsConfirmNewPassword: sameAs("confirmNewPassword"),
      },
      confirmNewPassword: {
        required,
        sameAsNewPassword: sameAs("newPassword"),
      },
    },
  },
  created() {
    this.getById();
  },
  methods: {
    getById() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/auth/forgot-password/${self.$route.query.token}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });

            self.$router.push({ name: "login" });
          } else {
            self.fullName = response.data.fullName;
          }
          loader.hide();
        });
    },
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      self.$dialog
        .confirm("Kamu yakin mau men-submit password ini ?", {
          okText: "Ya, Submit",
          cancelText: "Ga jadi",
          loader: true,
        })
        .then((dialog) => {
          self.$store
            .dispatch("apis/post", {
              url: `auth/change-password/${self.$route.query.token}`,
              params: self.form,
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });

                self.$router.push({ name: "login" });
              }
            })
            .finally(() => dialog.close());
        });
    },
  },
};
</script>
